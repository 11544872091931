import type { Slide } from 'yet-another-react-lightbox';
import { Lightbox } from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import 'yet-another-react-lightbox/plugins/captions.css';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import 'yet-another-react-lightbox/plugins/counter.css';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import Video from 'yet-another-react-lightbox/plugins/video';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';

interface Props {
  currentSlideIndex: number;
  setCurrentSlideIndex: (index: number) => void;
  slides: Slide[];
  onClose?: (currentSlideIndex: number) => void;
  onView?: (type: 'image' | 'video' | undefined) => void;
}

export default function PhotoGallery({
  currentSlideIndex,
  setCurrentSlideIndex,
  slides,
  onClose,
  onView,
}: Props) {
  return (
    <Lightbox
      plugins={[Fullscreen, Zoom, Captions, Counter, Video, Thumbnails]}
      captions={{ descriptionTextAlign: 'start' }}
      on={{
        view: (e) => {
          setCurrentSlideIndex(e.index);
          const isVideo = slides[e.index]?.type === 'video';
          if (isVideo && onView) {
            onView(slides[e.index]?.type);
          }
        },
      }}
      thumbnails={{
        width: 96,
        height: 72,
      }}
      counter={{
        container: { style: { top: 0, bottom: 'unset' } },
        separator: 'of',
      }}
      video={{
        autoPlay: true,
        controlsList: 'nodownload',
      }}
      zoom={{
        scrollToZoom: false,
        zoomInMultiplier: 2,
        maxZoomPixelRatio: 1000,
      }}
      index={currentSlideIndex}
      slides={slides}
      open={currentSlideIndex >= 0}
      close={() => {
        setCurrentSlideIndex(-1);
        onClose?.(currentSlideIndex);
      }}
      controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
    />
  );
}
